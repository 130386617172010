$(document).on("turbolinks:load", function () {
    $('#order_evaluation_licenses').change(function () {
        if ($(this).is(':checked')) {
            // $('.disable_with_evaluation_licenses').addClass('hidden');
            $('.disable_with_evaluation_licenses').fadeOut();
        } else {
            // $('.disable_with_evaluation_licenses').removeClass('hidden');
            $('.disable_with_evaluation_licenses').fadeIn();
        }
    });
});


document.addEventListener('turbolinks:load', () => {
    addListeners(document);
    let customer_select = document.querySelector("select#order_customer_id");
    if (customer_select) {
        customer_select.addEventListener('change', handleCustomerChange);
    }
    removeInvalidLicenseLocations();
    removeInvalidUpdateplanLicrefs();
    resetUpdateplans();
});

function addListeners(ele) {
    ele.querySelectorAll(".add_fields").forEach(function (e) {
        e.addEventListener("click", function (event) {
            let id = e.getAttribute('data-id');
            let fields = e.getAttribute('data-fields');

            let time = new Date().getTime();
            let regexp = new RegExp(id, 'g');
            let new_fields = fields.replace(regexp, time);

            e.insertAdjacentHTML('beforebegin', new_fields);
            addListeners(e.previousElementSibling);

            removeInvalidLicenseLocations();
            removeInvalidUpdateplanLicrefs();
            setNewLicenseLocation();
            recalculatePositions();

            event.preventDefault();
        });
    });

    ele.querySelectorAll(".remove_fields").forEach(function (e) {
        e.addEventListener("click", function (event) {
            event.preventDefault();
            if(!confirm("Are you sure?")) { return; }
            e.parentNode.querySelector('input[type=hidden]').value = 1;
            e.closest('fieldset').classList.add('hidden');
            event.preventDefault();
            recalculatePositions();
        });
    })

    ele.querySelectorAll(".license_product_select").forEach(function (e) {
        e.addEventListener("change", function (event) {
            let type = ($('option:selected', this).data('type'));
            let unit = ($('option:selected', this).data('unit'));

            let count_append = $(this).closest('.license').find('.product--unit .input-group .input-group-append span');
            count_append.text(unit);

            if (type === 'service' || type === 'upgrade' || type === 'updateplan') {
                let enddate_input = $(this).closest('.license').find('input[type="date"]');
                enddate_input.parent().parent().children().each(function () {
                    $(this).addClass('hidden');
                });
                enddate_input.val('');
                if(type === 'updateplan') {
                  enddate_input.parent().parent().parent().find('.message').text("Please make sure to set the enddate of the licenses for this update plan manually");
                }else {
                  enddate_input.parent().parent().parent().find('.message').text("");
                }
            } else {
                let months = ($('option:selected', this).data('months-included'));
                let date = new Date($('#order_activation_date').val());
                let enddate_input = $(this).closest('.license').find('input[type="date"]');
                enddate_input.parent().parent().children().each(function () {
                    $(this).removeClass('hidden');
                });
                date.setMonth(date.getMonth() + months);
                enddate_input.val(date.toISOString().substr(0, 10));
                enddate_input.parent().parent().parent().find('.message').text("");
            }
            console.log($('.license_enddate .license_enddate__label:not([data-labelfor~="' + type + '"])'));
            $('.license_enddate .license_enddate__label[data-labelfor~="' + type + '"]').show();
            $('.license_enddate .license_enddate__label:not([data-labelfor~="' + type + '"])').hide();

        });
    })

    ele.querySelectorAll(".updateplan_product_select").forEach(function (e) {
        e.addEventListener("change", function (event) {
            let months = ($('option:selected', this).data('months-included'));
            let date = new Date($(this).closest('.updateplan').find("[name$='license_enddate]']").val());
            let enddate_new = $(this).closest('.updateplan').find("[name$='[enddate]']");

            date.setMonth(date.getMonth() + months);
            // enddate_input.val(date.toISOString().substr(0, 10));
            enddate_new.val(date.toISOString().substr(0, 10));
        });
    })

    ele.querySelectorAll(".updateplan_licref_select").forEach(function (e) {
        e.addEventListener("change", function (event) {
            resetUpdateplans();
        });
    })

}

function handleCustomerChange() {
    resetLicenseLocations();
    resetUpdateplanLicrefs();
    // resetUpdateplanLicenses();
    resetUpdateplans();
    removeInvalidLicenseLocations();
    removeInvalidUpdateplanLicrefs();
    // removeInvalidUpdateplans();
    // removeInvalidUpdateplanLicenses();
}

function removeInvalidUpdateplanLicrefs() {
    // if we added fields for locations we have to remove the ones not belonging to the current selected customer
    let selectedCustomer = $('select#order_customer_id').val();
    let licrefSelect = $('#order_licref_ref');
    licrefSelect.find('[data-customer]').each(function () {
        if ($(this).data('customer') == selectedCustomer) {
            $(this).attr('hidden', false);
        } else {
            $(this).attr('hidden', true);
        }
    });
    licrefSelect.attr('disabled', selectedCustomer === '');
}

function removeInvalidLicenseLocations() {
    // if we added fields for locations we have to remove the ones not belonging to the current selected customer
    let selectedCustomer = $('select#order_customer_id').val();
    $('#license_list').find('[data-customer]').each(function () {
        if ($(this).data('customer') == selectedCustomer) {
            $(this).attr('hidden', false);
        } else {
            $(this).attr('hidden', true);
        }
    });
}

function resetLicenseLocations() {
    $('.license_location_select').each(function () {
        $(this).val('');
    });
}

function resetUpdateplanLicrefs() {
    $('.updateplan_licref_select').each(function () {
        $(this).val('');
    });
}

function resetUpdateplans() {
    console.log('reset updateplans');
    let selectedLicref = $('.updateplan_licref_select').val();
    console.log('selectedOrder: ' + selectedLicref);
    $('#updateplan_list').find('fieldset').each(function () {
        if ($(this).data('licref') == selectedLicref) {
            $(this).removeClass('hidden');
            $(this).find("[name$='license_id]']").attr('disabled', false);
            $(this).find("[name$='product_id]']").attr('disabled', false);
            $(this).find("[name$='_destroy]']").attr('disabled', false);
        } else {
            $(this).find("[name$='license_id]']").attr('disabled', true);
            $(this).find("[name$='product_id]']").attr('disabled', true);
            $(this).find("[name$='_destroy]']").attr('disabled', true);
            $(this).addClass('hidden');
        }
    });
}

function setNewLicenseLocation() {
    let previous, last;
    $('.license_location_select').each(function () {
        // exclude removed fieldsets
        if (!$(this).closest('fieldset').hasClass('hidden')) {
            previous = last;
            last = $(this);
        }
    });
    if (previous && last) {
        last.val(previous.val())
    }
}

function recalculatePositions() {
    $('#position_list > fieldset:not(".hidden")').each(function (index) {
        $(this).find('input[name$="[nr]"]').val(index + 1);
    });
}