$(document).on("turbolinks:load", function () {
  window.setAllDiscounts = (element) => {
    let producttype = $(element).data('producttype');
    let discount = parseFloat($('#set-discount-for-' + producttype).val());
    console.log("setting all discounts for " + producttype + ' to ' + discount);
    $('.discounts-' + producttype + ' input[type="number"]').each((i, e) => {
      console.log(e);
      $(e).val(discount);
    });
  };

  $('#reseller_default_discount, #distributor_default_discount').on('change', (default_discount) => {
    let $default_discount_field = $(default_discount.currentTarget);
    let olddiscount = default_discount.currentTarget.defaultValue;
    let newdiscount = $default_discount_field.val();
    $default_discount_field.data('lastval', newdiscount);

    console.log('changed default discount from ' + olddiscount + ' to ' + newdiscount);

    // update all input fields which have old default discount to new one
    $('.discounts input[value="' + olddiscount + '"]').each((i, e) => {
      console.log(e);
      console.log($(e).val());
      console.log(olddiscount);
      if($(e).attr('value') == olddiscount) {
        $(e).val(newdiscount);
      }
    });
  });
});

