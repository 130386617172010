$(document).on("turbolinks:load", function () {
    let activationFileForm = $('#activation_file_form');
    let requestDropdown = $('#activation_requestfile_dropdown');
    let licenseLocationFields = $('.license_location_fields');
    let spanActivationFileCount = $('.current-count-activation_file');

    requestDropdown.on('change', function(e) {
      requestfile = this.value;
      console.log("Request File ID: " + requestfile);
      // get the option for this value
      requestDropdown.find('option').each(function() {
        if($(this).val() === requestfile) {
          url = $(this).data('url');
          if (typeof url === 'undefined') {
            // use the default url
            url = requestDropdown.data('without-rf-url');
          }
          if(activationFileForm.hasClass('dirty')) {

            choice = confirm("You have unsaved progress. Are you sure you want to go on?");
            if(choice) {
              window.location = url;
            }
          }else{
             window.location = url;
          }
        }
      });
    });

    activationFileForm.find('input').on('change', function() {
      activationFileForm.addClass('dirty');
    });

    $('#activation_file_form.will-archive-activation_files').on('submit', function() {
      var proceed = confirm("This will archive your old Activation File. Are you sure?");
      return proceed;
    });

    window.calculateSums = () => {

      // Count in this activation file
      let sum = 0;
      $('input.licloc--count').each((i, e) => {
        let count = parseInt(e.value);
        if(!Number.isNaN(count)) {
          sum += count;
        }

        let parent = e.closest('fieldset');
        let spanLicenseCount = $(parent).find('.current-count-licref');
        let others_count = parseInt(spanLicenseCount.data('others'));
        let maximum = parseInt(spanLicenseCount.data('max'));
        if(Number.isNaN(others_count + count)) {
          spanLicenseCount.text('--');
        }else{
          spanLicenseCount.text(others_count + count);
          if(maximum < (others_count + count)){
            spanLicenseCount.addClass('text-danger');
            spanLicenseCount.removeClass('text-success');
          }else{
            spanLicenseCount.removeClass('text-danger');
            spanLicenseCount.addClass('text-success');
          }
        }
      });

      spanActivationFileCount.text(sum);
    }

    calculateSums();
});