$(document).on("turbolinks:load", function () {
	window.positionProductCodeChanged = (e) => {
		// check if there's a datalist entry with the same value
		// debugger
		let $row = $(e).closest("fieldset");
		let val = $(e).val();

		console.log("Popuplating for Product " + val);

		let found = $(e)
			.parent()
			.find("datalist")
			.find("option")
			.filter((i, option) => $(option).val() == val);

		let $product = $(found[0]);

		if (found.length) {
			let desc = $product.attr("data-desc");
			$row.find(".data-position-desc").val(desc);

			let msrp = $product.attr("data-msrp");
			$row.find(".data-position-msrp").val(msrp);

			let unit = $product.attr("data-unit");
			$row.find(".data-position-unit select").val(unit);

			let partnerdiscount = $product.attr("data-partnerdiscount");
			$row.find(".data-position-partnerdiscount").val(partnerdiscount);

			doCalculations();
		}
	};

	window.doCalculations = (e) => {
		console.log("calculating position");
		let $row = $(e).closest("fieldset");

		// the info we need
		let quantity = parseInt($row.find(".data-position-quantity").val());
		let unitprice = parseFloat($row.find(".data-position-msrp").val());
		let partnerdiscount = parseFloat(
			$row.find(".data-position-partnerdiscount").val()
		);

		// calculate the stuff
    // let msrptotal = quantity * unitprice;
    let partnerprice = unitprice - unitprice * ( partnerdiscount / 100 );
		let discountedtotal = partnerprice * quantity;

		//set the values back
		$row.find(".data-position-partner-price").val(partnerprice.toFixed(2));
		$row.find(".data-position-partner-total").val(discountedtotal.toFixed(2));

		calculateTotals();
	};

	window.calculateTotals = () => {
		console.log("calculating totals");

		let total_before_project_discount = 0;
		$(".data-position-partner-total").each((i, e) => {
			total_before_project_discount += parseFloat($(e).val());
		});

		console.log("total before discount:");
		console.log(total_before_project_discount);

		let project_discount = parseFloat(
			$(".data-invoice-project-discount").val()
		);
		console.log("Project discount is " + project_discount);

		let total = total_before_project_discount - (project_discount || 0);

    let display_sub = Number.isNaN(total_before_project_discount) ? '---' : total_before_project_discount.toFixed(2);
    let display_projectdiscount = Number.isNaN(project_discount) ? '0.00' : project_discount.toFixed(2);
    let display_total = Number.isNaN(total) ? '---' : total.toFixed(2);

    $(".data-invoice-total-before-project-discount").text(display_sub);
		$(".data-invoice-projectdiscount").text(display_projectdiscount);
		$(".data-invoice-total").text(display_total);
	};

	calculateTotals();
});
