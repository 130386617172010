$(document).on("turbolinks:load", function () {
    let customerDropdown = $('#file_customer_dropdown');
    let orderDropdown = $('#file_order_dropdown');
    let locationDropdown = $('#file_location_dropdown');

    let customer = customerDropdown.val();
    let order = orderDropdown.val();
    let location = locationDropdown.val();

    function hideDropdownOptions() {
        orderDropdown.find('option').each(function () {
            if($(this).data('customer') == customer){
                $(this).attr('hidden', false);
            }else{
                $(this).attr('hidden', true);
            }
        });
        locationDropdown.find('option').each(function () {
            if($(this).val() == '-1'){
              // this should always be there
            }else{
              if($(this).data('customer') == customer){
                  $(this).attr('hidden', false);
              }else{
                  $(this).attr('hidden', true);
              }
            }
        });
    }

    customerDropdown.on('change', function () {
        customer = this.value;
        orderDropdown.val('');
        orderDropdown.attr('disabled', false);
        locationDropdown.attr('disabled', false);
        locationDropdown.val('');
        hideDropdownOptions();
    });

    orderDropdown.on('change', function () {
        order = this.value;
        locationDropdown.val('');
        hideDropdownOptions();
    });

    locationDropdown.on('change', function() {
      location = this.value;

      // if it's create location, show the fields
      if(location === '-1') {
        $('#create-location').fadeIn(200);
      }else{
        $('#create-location').fadeOut(200);
      }
    });

    if(locationDropdown.value === '-1') {
      $('#create-location').fadeIn(200);
    }else{
      $('#create-location').fadeOut(200);
    }


    hideDropdownOptions();
});