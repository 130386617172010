$(document).on("turbolinks:load", function () {
  $('form.needs-confirmation').on('submit', function() {
    var proceed = confirm("Are you sure?");
    return proceed;
  });

  $('input[type="file"]').on('change', function() {
    //get the file name
    var fileNameArr = $(this).val().split("\\");
    var fileName = fileNameArr[fileNameArr.length -1 ]
    //replace the "Choose a file" label
    $(this).next('.custom-file-label').html(fileName);
  })

  $('form.warn-before-leaving').each(function(i, form){
    $(form).find('input').each(function(i, input) {
      $(input).on('change', function() {
        $(form).attr("changed", true);
      });
    });
  });

  $(document).on('turbolinks:before-visit', function() {
    console.log("Turbolinks");
  });
});

